import { Controller } from "@hotwired/stimulus"
import { animationState } from "../helpers/download_animation_handler"
import { buildUrl } from "../helpers/build_request"

// Connects to data-controller="document-export"
export default class extends Controller {
  static targets = ["form", "exportButton"]

  connect() {
    super.connect();
    
    this.exportButtonTargets.forEach((button) => {
      this.addExportListener(button, { useForm: this.hasFormTarget });
    })
  }

  addExportListener(button, { useForm }) {
    button.addEventListener("click", (event) => {
      event.preventDefault();
      animationState(event, "start");

      if (useForm) {
        this.handleFormDownload(button);
      } 
      else {
        this.handleDirectDownload(button);
      }
    })
  }

  async handleFormDownload(button) {
    try {
      const filterForm = this.formTarget;
      const submitWithParams = JSON.parse(button.dataset.submitWithParams);

      let formParams = new URLSearchParams(new FormData(filterForm));
      Object.entries(submitWithParams).forEach(([key, value]) => {
        if (value != null) {
          formParams.append(key, value);
        }
      });
  
      const filteredParams = new URLSearchParams();
  
      for (const [key, value] of formParams.entries()) {
        if (value.trim() !== '') {
          filteredParams.append(key, value);
        }
      }
  
      const url = buildUrl(filterForm.action, Object.fromEntries(filteredParams));
      await this.download(url);
    } 
    catch (error) {
      console.error("Form download failed:", error);
    } 
    finally {
      animationState({ target: button }, "finish");
    }
  }

  async handleDirectDownload(button) {
    try {
      await this.download(button.href);
    } 
    catch (error) {
      console.error("Direct download failed:", error);
    } 
    finally {
      animationState({ target: button }, "finish");
    }
  }

  async download(url) {
    const response = await fetch(url);

    if (!response.ok) {
      throw new Error("Network response was not ok.");
    }

    const blob = await response.blob();
    const downloadUrl = URL.createObjectURL(blob);
    const tempLink = document.createElement("a");

    tempLink.href = downloadUrl;
    tempLink.download = decodeURIComponent(response.headers.get("File-Name"));
    document.body.appendChild(tempLink);
    tempLink.click();
    tempLink.remove();

    URL.revokeObjectURL(downloadUrl);
  }
}
